import React from 'react';

import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

import './Reseaux.css'

const Reseaux = () => (
  <div className="section__padding" id="reseaux">
    <h1 className="app__h3-social">Retrouvez-nous sur nos réseaux sociaux</h1>
    <div className="app__social-icons">
      <a href="https://www.facebook.com/HummAction/" target="_blank"><FaFacebook color="#0066b2" fontSize={27} style={{ margin: '0 1rem', fontWeight: 'bolder' }}/></a>
      <a href="https://twitter.com/HummAction" target="_blank"><FaTwitter color="#0066b2" fontSize={27} style={{ margin: '0 1rem', fontWeight: 'bolder' }}/></a>
      <a href="https://www.instagram.com/hummaction/" target="_blank"><FaInstagram color="#0066b2" fontSize={27} style={{ margin: '0 1rem', fontWeight: 'bolder' }}/></a>
    </div>
  </div>
  
);

export default Reseaux;
